import styled, {
  backgroundColor,
  BackgroundColorProps,
  borders,
  BordersProps,
  compose,
  css,
  display,
  DisplayProps,
  flexboxes,
  FlexboxesProps,
  th,
} from '@xstyled/styled-components'
import NextLink from 'next/link'

import { variant } from '@/shared/utils/styled'

import { Text } from '../Text'

type LinkSystemProps = DisplayProps & FlexboxesProps & BackgroundColorProps & BordersProps<any>
const linkSystemProps = compose(display, flexboxes, backgroundColor, borders)

const variants = {
  none: {},
  primary: css`
    font-size: ${th.fontSize('sm')};
    font-weight: semibold;
    text-decoration: underline;
  `,
  inline: css`
    display: inline;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline;
  `,
  subnav: css`
    position: relative;
    padding: 10px 15px;
    color: ${th.color('grey.48')};
    line-height: 21px;
    text-decoration: none;
    border-radius: 5px;

    &[data-state='active'] {
      color: ${th.color('grey.100')};
      font-weight: semibold;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: ${th.color('purple.100')};
        border-radius: 5px;
      }
    }

    &:hover {
      background-color: ${th.color('grey.4')};
    }
  `,
}

const linkVariant = variant({
  default: 'none',
  variants,
})

export type LinkVariantProps = { readonly variant?: keyof typeof variants }

export type StyledLinkProps = Omit<SCProps<typeof StyledLink>, 'href'>

export const StyledLink = styled(Text)<LinkVariantProps & LinkSystemProps & { href?: any }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    cursor: pointer;
  }

  ${linkVariant as any}
  ${linkSystemProps as any}
`

export const StyledNextLink = styled(NextLink)`
  text-decoration: none;
`
