import { x } from '@xstyled/styled-components'

import { Images } from '@/constants'

type OrbitLogoProps = {
  size?: number
} & SCProps<typeof x.img>

export const OrbitLogo = ({ size = 24, ...rest }: OrbitLogoProps) => {
  return <x.img src={Images.Logo} alt="Orbit logo" width={size} {...rest} />
}
