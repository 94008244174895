import { getWorkspaceSlugFromPath } from '@/utils'

export const ROUTES = {
  home: '/',
  auth: {
    base: '/auth',
    get login() {
      return `${this.base}/login`
    },
    get signup() {
      return `${this.base}/signup`
    },
    get forgotPassword() {
      return `${this.base}/forgot-password`
    },
    get resetPassword() {
      return `${this.base}/reset-password`
    },
  },
  objectives: {
    base() {
      const workspaceSlug = getWorkspaceSlugFromPath()
      if (!workspaceSlug) return '/objectives'

      return `/${workspaceSlug}/objectives`
    },
    details(objectiveId: string | number, sideTab = 'key-results') {
      return `${this.base()}/${objectiveId}/details?sideTab=${sideTab}`
    },
    linkedOpportunities(objectiveId: string | number) {
      return `${this.base()}/${objectiveId}/opportunities`
    },
  },
  opportunities: {
    base() {
      const workspaceSlug = getWorkspaceSlugFromPath()
      if (!workspaceSlug) return '/opportunities'

      return `/${workspaceSlug}/opportunities`
    },
    opportunity(opportunityId: string | number) {
      return `${this.base()}/${opportunityId}?sideTab=properties&space=problem`
    },
    problemSpace(opportunityId: string | number, sideTab = 'properties') {
      return `${this.base()}/${opportunityId}?sideTab=${sideTab}&space=problem`
    },
    solutionSpace(opportunityId: string | number, sideTab = 'properties') {
      return `${this.base()}/${opportunityId}?sideTab=${sideTab}&space=solution`
    },
    evidenceSpace(opportunityId: string | number, sideTab = 'properties') {
      return `${this.base()}/${opportunityId}?sideTab=${sideTab}&space=evidence`
    },
    evidence(opportunityId: string | number, evidenceId: string | number) {
      return `${this.base()}/${opportunityId}?sideTab=properties&space=evidence#evidence_${evidenceId}`
    },
    opportunityComment(opportunityId: string | number, commentId: string | number) {
      return `${this.base()}/${opportunityId}?sideTab=comments&space=problem#comment_${commentId}`
    },
  },
  personas: {
    base() {
      const workspaceSlug = getWorkspaceSlugFromPath()
      if (!workspaceSlug) return '/personas'

      return `/${workspaceSlug}/personas`
    },
    details(personaId: string | number) {
      return `${this.base()}/${personaId}/details`
    },
    persona(personaId: string | number) {
      return `${this.base()}/${personaId}/persona`
    },
    feedback(personaId: string | number) {
      return `${this.base()}/${personaId}/feedback`
    },
    opportunities(personaId: string | number) {
      return `${this.base()}/${personaId}/opportunities`
    },
  },
  feedback: {
    base() {
      const workspaceSlug = getWorkspaceSlugFromPath()
      if (!workspaceSlug) return '/feedback'

      return `/${workspaceSlug}/feedback`
    },
    note(noteId: string | number, activeView = 'all') {
      return `${this.base()}?note=${noteId}&view=${activeView}`
    },
    evidence(noteId: string | number, evidenceId: string | number, activeView = 'all') {
      return `${this.base()}?note=${noteId}&evidence=${evidenceId}&view=${activeView}`
    },
    mention(noteId: string | number, mentionId: string | number, activeView = 'all') {
      return `${this.base()}?note=${noteId}&mention=${mentionId}&view=${activeView}`
    },
  },
  interviews: {
    base() {
      const workspaceSlug = getWorkspaceSlugFromPath()
      if (!workspaceSlug) return '/interviews'

      return `/${workspaceSlug}/interviews`
    },
    interview(interviewId: string | number) {
      return `${this.base()}?interview=${interviewId}`
    },
    evidence(interviewId: string | number, evidenceId: string | number) {
      return `${this.base()}?interview=${interviewId}&evidence=${evidenceId}`
    },
    mention(interviewId: string | number, mentionId: string | number) {
      return `${this.base()}?interview=${interviewId}&mention=${mentionId}`
    },
  },
  dashboards: {
    base() {
      const workspaceSlug = getWorkspaceSlugFromPath()
      if (!workspaceSlug) return '/dashboards'

      return `/${workspaceSlug}/dashboards`
    },
    dashboard(dashboardId: string | number) {
      return `${this.base()}/${dashboardId}`
    },
    collection(dashboardId: string | number, collectionId: string | number) {
      return `${this.base()}/${dashboardId}?collection=${collectionId}`
    },
    collectionEvidence(dashboardId: string | number, collectionId: string | number, evidenceId: string | number) {
      return `${this.base()}/${dashboardId}?collection=${collectionId}#evidence_${evidenceId}`
    },
  },
  roadmaps: {
    base() {
      const workspaceSlug = getWorkspaceSlugFromPath()
      if (!workspaceSlug) return '/roadmaps'

      return `/${workspaceSlug}/roadmaps`
    },
    roadmap(roadmapId: string | number) {
      return `${this.base()}/${roadmapId}`
    },
    roadmapOpportunityItem(roadmapId: string | number, opportunityId: string | number) {
      return `${this.base()}/${roadmapId}?opportunity=${opportunityId}&sideTab=properties&space=problem`
    },
  },
  treemap: {
    base() {
      const workspaceSlug = getWorkspaceSlugFromPath()
      if (!workspaceSlug) return '/treemap'

      return `/${workspaceSlug}/treemap`
    },
    solution(solutionId: string | number) {
      return `${this.base()}?solution=${solutionId}`
    },
    opportunity(opportunityId: string | number) {
      return `${this.base()}?opportunity=${opportunityId}`
    },
    objective(objectiveId: string | number) {
      return `${this.base()}?objective=${objectiveId}`
    },
  },
  integrations: {
    base() {
      const workspaceSlug = getWorkspaceSlugFromPath()
      if (!workspaceSlug) return '/settings/integrations'

      return `/${workspaceSlug}/settings/integrations`
    },
    get intercom() {
      return `${this.base()}/intercom`
    },
    get jira() {
      return `${this.base()}/jira`
    },
    get slack() {
      return `${this.base()}/slack`
    },
    get zapier() {
      return `${this.base()}/zapier`
    },
    get zoom() {
      return `${this.base()}/zoom`
    },
  },
  settings: {
    base() {
      const workspaceSlug = getWorkspaceSlugFromPath()
      if (!workspaceSlug) return '/settings'

      return `/${workspaceSlug}/settings`
    },
    get general() {
      return `${this.base()}/general`
    },
    get members() {
      return `${this.base()}/members`
    },
    get copilot() {
      return `${this.base()}/copilot`
    },
    get profile() {
      return `${this.base()}/profile`
    },
    get preferences() {
      return `${this.base()}/preferences`
    },
    get notifications() {
      return `${this.base()}/notifications`
    },
    get apiKeys() {
      return `${this.base()}/api-keys`
    },
    get billing() {
      return `${this.base()}/billing`
    },
    get integrations() {
      return `${this.base()}/integrations`
    },
  },
  checkout: () => {
    const workspaceSlug = getWorkspaceSlugFromPath()
    if (!workspaceSlug) return '/checkout'

    return `/${workspaceSlug}/checkout`
  },
  onboarding() {
    const workspaceSlug = getWorkspaceSlugFromPath()
    if (!workspaceSlug) return '/onboarding'

    return `/${workspaceSlug}/onboarding`
  },
  activity: '/activity',
  farewell: '/farewell',
}
