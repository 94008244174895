import Head from 'next/head'
import React from 'react'

export type WithHeadOptions = { title?: string }

export const withHead = (Component: any, { title }: WithHeadOptions) => {
  const displayTitle = `Orbit | ${title}`

  const WithHead = (props: any) => (
    <>
      <Head>
        <title>{displayTitle}</title>
      </Head>
      <Component {...props} />
    </>
  )

  const displayName = Component.displayName || Component.name || 'Component'
  WithHead.displayName = `withHead(${displayName})`
  WithHead.getLayout = Component.getLayout
  if (Component.getInitialProps) {
    WithHead.getInitialProps = Component.getInitialProps
  }

  return WithHead
}
