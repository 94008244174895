import { useRouter } from 'next/router'

interface UseIsActiveProps {
  href: any
  matchHref?: string
  exact?: boolean
}

export const useIsActive = ({ href, matchHref, exact }: UseIsActiveProps) => {
  const router = useRouter()
  const pathToMatch = (matchHref || href) as string
  const pathName = router?.asPath

  if (!href) return false
  if (!exact) return pathName?.startsWith(pathToMatch)

  return pathName === pathToMatch
}
