import { keyframes } from '@xstyled/styled-components'

export const dropdownSlideUp = keyframes({
  '0%': { opacity: 0, transform: 'translateY(10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

export const dropdownSlideDown = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

export const dropdownSlideRight = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-10px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

export const dropdownSlideLeft = keyframes({
  '0%': { opacity: 0, transform: 'translateX(10px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

export const dropdownPanOut = keyframes({
  '0%': { opacity: 1, transform: 'scale(1)', maxHeight: 300, overflowY: 'hidden' },
  '100%': { opacity: 0, transform: 'scale(0.9)', maxHeight: 100, overflowY: 'hidden' },
})

export const dropdownRollDownFromTop = keyframes({
  '0%': { opacity: 0, maxHeight: '34px', overflowY: 'hidden' },
  '100%': { opacity: 1, maxHeight: '300px', overflowY: 'hidden' },
})

export const dropdownRollUpToTop = keyframes({
  '0%': { opacity: 1, maxHeight: '300px', overflowY: 'hidden' },
  '100%': { opacity: 0, maxHeight: '34px', overflowY: 'hidden' },
})

export const modalScaleUp = keyframes({
  '0%': { opacity: 0, transform: 'scale(0.95)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
})

export const modalScaleDown = keyframes({
  '0%': { opacity: 1, transform: 'scale(1)' },
  '100%': { opacity: 0, transform: 'scale(0.9)' },
})

export const scaleFadeIn = keyframes({
  '0%': { opacity: 0, transform: 'scale(0.95)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
})

export const scaleFadeOut = keyframes({
  '0%': { opacity: 1, transform: 'scale(1)' },
  '100%': { opacity: 0, transform: 'scale(0.95)' },
})

export const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

export const fadeOut = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
})

export const accordionOpen = keyframes({
  from: { height: 0, maxHeight: 0 },
  to: { height: `var(--radix-accordion-panel-height)`, maxHeight: '100%' },
})

export const accordionClose = keyframes({
  from: { height: `var(--radix-accordion-panel-height)`, maxHeight: '100%' },
  to: { height: 0, maxHeight: 0 },
})

export const zoomIn = keyframes({
  '0%': { transform: 'scale(0.96)', opacity: 0 },
  '100%': { transform: 'scale(1)', opacity: 1 },
})
