import { LinkProps as NextLinkProps } from 'next/link'
import React from 'react'

import { LinkVariantProps, StyledLink, StyledLinkProps, StyledNextLink } from './Link.styled'
import { useIsActive } from './hooks'

type LinkProps = Pick<NextLinkProps, 'href'> & StyledLinkProps & { isEnabled?: boolean }

export const Link = ({ href, isEnabled = true, ...rest }: LinkProps) => {
  if (!isEnabled) return <StyledLink {...rest} tabIndex={0} />

  return (
    <StyledNextLink href={href} passHref>
      <StyledLink {...rest} />
    </StyledNextLink>
  )
}

interface NavLinkExtraProps {
  exact?: boolean
  matchHref?: string
  variant?: LinkVariantProps['variant']
  isEnabled?: boolean
}

type NavLinkProps = Pick<NextLinkProps, 'href'> & StyledLinkProps & NavLinkExtraProps

export const NavLink = ({ href, exact, matchHref, isEnabled, onClick, ...rest }: NavLinkProps) => {
  const isActive = useIsActive({ href, matchHref, exact })

  return (
    <Link
      href={href}
      data-state={isActive ? 'active' : ''}
      isEnabled={!onClick && isEnabled}
      onClick={onClick}
      {...rest}
    />
  )
}
