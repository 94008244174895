import styled, {
  BackgroundColorProps,
  BorderRadiusProps,
  BordersProps,
  SizingProps,
  SpaceProps,
  TypographyProps,
  backgroundColor,
  borderRadius,
  borders,
  compose,
  css,
  sizing,
  space,
  th,
  typography,
  system,
} from '@xstyled/styled-components'

import { variant } from '@/shared/utils/styled'

import { BaseButton } from '../Button'

const variants = {
  primary: css`
    color: ${th.color('white')};
    font-weight: semibold;
    border-radius: 5px;
    border: 1px solid transparent;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: ${th.color('purple.64')};
      }
    }

    &:disabled {
      background-color: ${th.color('grey.16')};
    }
    &:hover {
      background-color: ${th.color('purple.16')};
    }
    &:focus-visible {
      background-color: ${th.color('purple.16')};
      outline: 2px solid ${th.color('purple.16')};
      outline-offset: 5px;
      outline-style: auto;
    }
    &:active {
      background-color: ${th.color('purple.16')};
    }
  `,
  secondary: css`
    font-weight: normal;
    background-color: ${th.color('white')};
    border-radius: 5px;
    color: ${th.color('grey.48')};
    &:disabled {
      color: ${th.color('grey.24')};
    }
    &:hover {
      color: ${th.color('grey.64')};
      background-color: ${th.color('grey.4')};
    }
    &:focus-visible {
      color: ${th.color('grey.64')};
      background-color: ${th.color('grey.4')};
      outline: 2px solid ${th.color('purple.64')};
      outline-offset: 5px;
      outline-style: auto;
    }
    &:active {
      color: ${th.color('purple.64')};
      background-color: ${th.color('purple.4')};
    }
  `,
}

const iconButtonVariant = variant({
  default: 'secondary',
  variants,
})

type IconButtonVariantProps = { readonly variant?: keyof typeof variants }

type IconButtonProps = SpaceProps &
  SizingProps &
  TypographyProps &
  BackgroundColorProps &
  BordersProps<any> &
  BorderRadiusProps &
  IconButtonVariantProps & {
    size?: number
  }
const iconButtonSystemProps = compose(space, sizing, typography, borders, borderRadius, backgroundColor)

export const IconButton = styled(BaseButton)<IconButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size = 34 }) => size}px;
  min-width: ${({ size = 34 }) => size}px;
  height: ${({ size = 34 }) => size}px;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;

  &[data-visible='false'] {
    opacity: 0;
  }

  &[data-visible='true'] {
    opacity: 1;
  }

  &:hover:not([data-active='true']) {
    background-color: ${th.color('grey.4')};
  }

  &:focus-visible {
    background-color: ${th.color('grey.4')};
    outline: 2px solid ${th.color('purple.64')};
    outline-offset: 5px;
    outline-style: auto;
  }

  &:active,
  &[data-active='true'] {
    background-color: ${th.color('purple.8')};
    outline: none;
  }

  && {
    ${iconButtonVariant as any}
    ${iconButtonSystemProps}
    ${system}
  }
`
