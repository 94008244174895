import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styled, { SystemProps, system, th } from '@xstyled/styled-components'

import { scaleFadeIn, scaleFadeOut } from '@/theme'

export const Trigger = styled(DropdownMenu.Trigger)<SystemProps>`
  appearance: none;
  background-color: transparent;
  border: none;

  :focus {
    outline: none;
  }

  &[data-active='true'] {
    background-color: ${'grey.4'};
  }

  ${system}
`

export const SubTrigger = styled(DropdownMenu.SubTrigger)<SystemProps>`
  appearance: none;
  background-color: transparent;
  border: none;

  :focus {
    outline: none;
  }

  ${system}
`

export const Content = styled(DropdownMenu.Content)<SystemProps>`
  list-style: none;
  background-color: ${th('colors.white')};
  border-radius: 5px;
  box-shadow: ${th('shadows.normal')};
  border: 1px solid ${th.color('grey.12')};
  overflow: hidden;

  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
  transition:
    transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0s,
    opacity 0.2s ease-in-out;
  transform-origin: var(--radix-dropdown-menu-content-transform-origin);

  &[data-state='open'] {
    animation-name: ${scaleFadeIn};
  }

  &[data-state='closed'] {
    animation-name: ${scaleFadeOut};
  }

  z-index: 5;
  ${system}
`
Content.defaultProps = {
  sideOffset: 5,
}

export const SubContent = styled(DropdownMenu.SubContent)`
  background-color: white;

  border-radius: 5px;
  box-shadow: normal;
  pointer-events: auto;
  overflow-x: hidden;

  animation-duration: 0.1s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
  transition:
    transform 0.1s cubic-bezier(0.16, 1, 0.3, 1),
    opacity 0.1s ease-in-out;
  will-change: transform, opacity;

  border: 1px solid ${th.color('grey.12')};

  transform-origin: var(--radix-dropdown-menu-content-transform-origin);

  &[data-state='open'] {
    animation-name: ${scaleFadeIn};
  }

  &[data-state='closed'] {
    animation-name: ${scaleFadeOut};
  }
`

export const Item = styled(DropdownMenu.Item)<SystemProps>`
  display: flex;

  width: 100%;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: ${'grey.100'};
  border-radius: 5px;

  font-size: 14px;

  transition: background-color 0.15s ease-in-out;

  &:hover,
  &[data-active='true'] {
    background-color: ${th.color('grey.4')};
  }

  &:focus {
    outline: none;
  }

  ${system}
`
