import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'

import { Separator } from '../Separator'
import * as S from './DropdownMenu.styled'

export const DropdownMenu = {
  ...RadixDropdownMenu,
  Trigger: S.Trigger,
  SubTrigger: S.SubTrigger,
  Content: S.Content,
  SubContent: S.SubContent,
  Item: S.Item,
  Separator,
}
