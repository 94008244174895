import { isClient } from '@/shared/utils'

export const getWorkspaceSlugFromPath = (): string => {
  if (!isClient) return ''

  const path = window.location.pathname

  const pathParts = path.split('/')
  const workspaceSlug = pathParts[1]

  return workspaceSlug
}
